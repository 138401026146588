<template>
  <section class="py-10 lg:py-20 bg-gray-50">
    <div class="container mx-auto px-4">
      <div class="max-w-xl mx-auto">
        <div class="mb-10">
          <a class="text-3xl font-bold leading-none" href="#">
            <img class="h-12 mx-auto" src="atis-assets/logo/atis/atis-mono-black.svg" alt="" width="auto">
          </a>
        </div>
        <div class="mb-6 lg:mb-10 p-6 lg:p-12 bg-white shadow rounded text-center">
          <div class="mb-6">
            <h3 class="text-2xl font-bold">Reset Password</h3>
          </div>
          <form action="">
            <div class="mb-3 flex p-4 bg-gray-50 rounded">
              <input v-model="password" class="w-full text-xs bg-gray-50 outline-none" type="password" placeholder="Enter your new Password">
              <button>
                <svg class="h-6 w-6 ml-4 my-auto text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                </svg>
              </button>
            </div>
            <div class="text-center">
              <button @click="restPassword()" class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50">Set Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Reset Password',
  data () {
    return {
      password: null,
      userId: this.$route.params.userId,
      token: this.$route.params.token,
    }
  },
    methods: {
    async restPassword() {
      const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/users/${this.userId}/reset-password`, {
        token: this.token,
        password: this.password,
      })

      if (response.status === 200) {
        this.$router.push({ name: 'Login', query: { fromResetPassword: true } })
      }

    },
  },
}
</script>